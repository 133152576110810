<template>
  <div class="login-side-container">
    <div class="header">

      <!--Content before waves-->
      <div class="inner-header login-side-flex">
        <!--Just the logo.. Don't mind this-->
        <transition
          enter-active-class="animated rotate"
          leave-active-class="animated rotate"
        >
          <q-img
            class="login-logo-img"
            contain
            :ratio="12/9"
            :src="imgSrc"
          >
          </q-img>
        </transition>
      </div>

      <!--Waves Container-->
      <div class="waves-container">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(10,85,151,0.7)" />
            <use xlink:href="#gentle-wave" x="48" y="1" fill="rgba(10,85,151,0.5)" />
            <use xlink:href="#gentle-wave" x="48" y="2" fill="rgba(10,85,151,0.3)" />
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(10,85,151,1)" />
          </g>
        </svg>
      </div>
      <!--Waves end-->

    </div>
    <!--Header ends-->

    <!--Content starts-->
    <div class="content flex">
      <!-- <p>By.Goodkatz | Free to use </p> -->
    </div>
    <!--Content ends-->
  </div>
</template>

<script>
export default {
  name: 'LoginSide',
  data: () => ({
    imgSrc: require('@/assets/images/guyoung_login.png'),
  }),
  watch: {
  },
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
  }
};
</script>
<style lang="scss">
// @import url(//fonts.googleapis.com/css?family=Lato:300:400);
.login-logo-img {
  width: 90%;
  height: 80%;
}

body {
  margin:0;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:48px;
}
p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size:14px;
  color: #333333;
}

.login-side-container {
  height: 100%;
  width: 100%;
}

.header {
  position:relative;
  text-align:center;
  // #C2D2EB
  background: rgb(255, 255, 255);
  // background: linear-gradient(60deg, rgba(194,210,235,1) 0%, rgb(255, 255, 255) 100%);
  // background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:30vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.login-side-flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves-container {
  height: 15vh;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  // min-height:100px;
  // max-height:110px;
}

.content {
  position:relative;
  height:5vh;
  text-align:center;
  background-color: rgb(10,85,151);
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 27s;
}
.parallax > use:nth-child(2) {
  animation-delay: -10s;
  animation-duration: 30s;
}
.parallax > use:nth-child(3) {
  animation-delay: -18s;
  animation-duration: 33s;
}
.parallax > use:nth-child(4) {
  animation-delay: -26s;
  animation-duration: 40s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
// @media (max-width: 768px) {
//   .waves {
//     height:40px;
//     min-height:40px;
//   }
//   .content {
//     height:30vh;
//   }
//   h1 {
//     font-size:24px;
//   }
// }

@media (max-width: 1200px) {
  .inner-header {
    height: 45vh;
  }
  .waves-container {
    height: 25vh;
  }
  .waves {
    height: 25vh;
  }
  .content {
    height: 10vh;
  }
}

@media (max-width: 991.98px) {
  .inner-header {
    height: 30vh;
  }
  .waves-container {
    height: 15vh;
  }
  .waves {
    height: 15vh;
  }
  .content {
    height: 5vh;
  }
}
</style>